<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    dark
    app
    mobile-breakpoint="960"
  >
    <v-divider class="mb-1" />

    <v-row
      align="center"
      class="pa-3"
    >
      <v-col
        cols="7"
        class="text-center pr-0"
      >
        <h1 class="display-1 white--text font-weight-black">
          {{ $store.state.usuario.usuario !== null ? $store.state.usuario.usuario.empresa.descricao : 'Safety' }}
        </h1>
      </v-col>
      <v-col
        cols="5"
        class="text-left"
      >
        <img
          v-if="$store.state.usuario.usuario && $store.state.usuario.usuario.empresa.imagem"
          :src="caminhoImagemEmpresa + $store.state.usuario.usuario.empresa.imagem"
          style="max-width: 100%; height: auto"
        >
      </v-col>
    </v-row>

    <v-divider class="mb-2" />

    <v-list
      dense
      nav
    >
      <v-list-item
        to="/"
        active-class="primary white--text"
        link
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-view-dashboard'" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Dashboard'" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="$temPermissao('ocorrencia-listagem')"
        to="/ocorrencias"
        active-class="primary white--text"
        link
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-format-list-text'" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Ocorrências'" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        to="/alarmes"
        active-class="primary white--text"
        link
      >
        <v-list-item-icon>
          <v-icon
            v-text="'mdi-alarm-light'"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Alarmes'" />
        </v-list-item-content>
      </v-list-item>

      <template v-for="(item, i) in menu">
        <template v-if="item.itens">
          <v-list-group
            :key="`group-${i}`"
            no-action
            :prepend-icon="item.icone"
            active-class="secondary white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.texto" />
              </v-list-item-content>
            </template>

            <template v-for="(submenu, si) in item.itens">
              <v-list-item
                v-if="submenu.mostrar === true"
                :key="`submenu-${si}`"
                :to="submenu.link"
                active-class="primary white--text"
              >
                <v-list-item-icon>
                  <v-icon v-text="submenu.icone" />
                </v-list-item-icon>

                <v-list-item-title v-text="submenu.texto" />
              </v-list-item>
            </template>
          </v-list-group>
        </template>

        <template v-else>
          <v-list-item
            :key="`group-${i}`"
            :to="item.link || ''"
            active-class="primary white--text"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="item.icone" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.texto" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import menu from '@/utils/menu'

  export default {

    data: () => ({
      menu: menu(),
      caminhoImagemEmpresa: process.env.VUE_APP_URL_SERVIDOR + 'storage/imagens_empresas/',
    }),

    computed: {
      drawer: {
        get () {
          return this.$store.state.layout.drawer
        },
        set (val) {
          this.$store.commit('layout/SET_DRAWER', val)
        },
      },
      mostrar: {
        get () {
          return this.value
        },
        set (value) {
          document.querySelector('html').style.overflow = value ? 'hidden' : null
          this.$emit('input', value)
        },
      },
    },

  }
</script>

<style lang="sass">
  .v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item
    padding-left: 30px !important

  #core-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar
      width: 11px !important

  #core-navigation-drawer .v-navigation-drawer__content
      scrollbar-width: thin !important
      scrollbar-color: #AEAEAE !important

  #core-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar-track
      background: #dfdfdf !important

  #core-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar-thumb
      background-color: #AEAEAE !important
      border-radius: 6px !important
      border: 3px solid #dfdfdf !important

</style>
