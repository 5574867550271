import store from '@/store'

export default () => [
  {
    texto: 'Relatórios',
    icone: 'mdi-ballot-outline',
    itens: [
      {
        texto: 'Estatísticas',
        icone: 'mdi-information',
        link: '/relatorios/estatisticas',
        mostrar: true,
      },
      {
        texto: 'Leituras Sem Placa',
        icone: 'mdi-help-box',
        link: '/relatorios/leituras-sem-placa',
        mostrar: true,
      },
      {
        texto: 'Placas Corrigidas',
        icone: 'mdi-sticker-check',
        link: '/relatorios/placas-corrigidas',
        mostrar: true,
      },
      {
        texto: 'Visualização de Acessos',
        icone: 'mdi-sticker-text-outline',
        link: '/relatorios/visualizacao-acessos',
        mostrar: store.getters['usuario/temPermissao']('visualizacao-acessos'),
      },
      {
        texto: 'Status Cameras',
        icone: 'mdi-cctv',
        link: '/pontos-captura/status-por-ponto-captura',
        mostrar: store.getters['usuario/temPermissao']('ponto-captura-listagem'),
      },
    ],
  },
  {
    texto: 'Cadastros',
    icone: 'mdi-cog',
    itens: [
      {
        texto: 'Usuários',
        icone: 'mdi-account',
        link: '/usuarios',
        mostrar: store.getters['usuario/temPermissao']('usuarios-listagem'),
      },
      {
        texto: 'Grupos',
        icone: 'mdi-account-group',
        link: '/grupos',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
      {
        texto: 'Empresas',
        icone: 'mdi-office-building-outline',
        link: '/empresas',
        mostrar: store.getters['usuario/temPermissao']('empresa-listagem'),
      },
      {
        texto: 'Situações Ocorrência',
        icone: 'mdi-dots-hexagon',
        link: '/situacoes-ocorrencia',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
      {
        texto: 'Naturezas Ocorrência',
        icone: 'mdi-dots-grid',
        link: '/naturezas-ocorrencia',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
      {
        texto: 'Marcas',
        icone: 'mdi-shield-car',
        link: '/veiculos/marca',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
      {
        texto: 'Modelos',
        icone: 'mdi-car',
        link: '/veiculos/modelo',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
      {
        texto: 'Tipos Ocorrência',
        icone: 'mdi-dots-triangle',
        link: '/tipos-ocorrencia',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
      {
        texto: 'Pontos de Captura',
        icone: 'mdi-cctv',
        link: '/pontos-captura',
        mostrar: store.getters['usuario/temPermissao']('ponto-captura-listagem'),
      },
      {
        texto: 'Perguntas Alertas',
        icone: 'mdi-chat-question',
        link: '/alertas-perguntas',
        mostrar: store.getters['usuario/temPermissao']('grupos-listagem'),
      },
    ],
  },
]
